.locSearchBar {
    padding-bottom: 3vh;
}

.pickLoc {
    font-size: 1rem;
    font-weight: bold;
    padding-bottom: 1vh;
}

.map {
    width: 100%;
    height: 50vh;
    position: relative;
}

.resultList {
    width: 80%;
    height: 100%;
    position: absolute;
    left: 10%;
    z-index: 999;
    border-radius: 20px;
    background: #ffffff;
    box-shadow: 20px 20px 60px #cabed9;
}

.listItem {
    min-height: 14%;
    width: 90%;
    display: grid;
    place-items: center;
    background: #d2c0f1;
    margin: 0 auto 1% auto;
    font-weight: bold;
    font-size: 0.5rem;
}

.listItem:hover {
    background: #8b51ef;
    color: #ffff;
    cursor: pointer;
}

.resultTitle {
    display: grid;
    place-items: center;
    font-weight: bold;
    color: #931436;
    height: 10%;
}

.cancelButton {
    height: 10%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    margin-right: 2vw;
}


.mainList {
    height: 80%;
}
