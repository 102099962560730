.GlassCard {
    width: 96vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    box-sizing: border-box;
    font-family: "Poppins", serif;
}

.GlassCard::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(#6db9cc, #bbe4ee);
    clip-path: circle(30% at right 70%);
}


.GlassCard::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(#f1d6d9, #ffdde1);
    clip-path: circle(20% at 10% 10%);
}


.title{
    position: absolute;
    top:30px;
    color:#000000;
    font-size: 50px;
}

.GlassCard .container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    z-index: 1;
}

.GlassCard  .container .card {
    position: relative;
    width: 280px;
    height: 400px;
    background-color: rgba(255, 238, 238,0.1);
    margin: 30px;
    border-radius: 15px;
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
    cursor: pointer;
}

.cardTitle{
    width: 280px;
    text-align: center;
    color: #312f2f;
    top:180px;
    opacity: 1;
    font-size: 30px;
}

.tip{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: red;
    clip-path: circle(13% at right 1%);
}

.tip::before{
    content:"22";
}






