.drawer {
    position: relative;
}

.block {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    width: calc(100% - 40px);
    margin-bottom: 20px;
}

.blockTitle {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #073e99;
    border-bottom: 1px solid #ebe9e9;
    height: 40px;
    line-height: 40px;
}


.infoItem {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    margin-bottom: 5px;
    width: 100%;
}

.infoTitle {
    font-weight: bold;
    color: #0052D9;
    width: 120px;
}

.infoContent {
    width: calc(100% - 120px);
}

.bottomButton {
    position: absolute;
    bottom: 10px;
    left: calc(50% - 150px);
    width: 300px;
}

.bottomButton_left {
    position: absolute;
    bottom: 10px;
    left: calc(25% - 150px);
    width: 300px;
}

.bottomButton_right {
    position: absolute;
    bottom: 10px;
    right: calc(25% - 150px);
    width: 300px;
}

.blockBottom{
    margin-top: 20px;
    border-top:  1px solid #ebe9e9;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
}

.signInProcessArea {
    width: 200px;
    margin: 10px calc(50% - 75px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.signProcessBlockSuccess, .signProcessBlockError {
    height: 50px;
    width: 30px;
    background: #31d531;
}

.signProcessBlockError {
    background: red;
}

.signInProcessStatics {
    text-align: center;
    width: 60px;
}

.trainingBlockArea{
    width: 96%;
    margin: 10px 0;
    padding: 10px 2%;
    background: linear-gradient(to bottom, #e0e0e0, #f0f0f0);
    border-radius: 4px;
}

.blockInfoItem{
    margin-bottom: 20px;
}

.blockInfoTitle{
    font-weight: bold;
    color: #487aa0
}

.blockInfoContent{
    margin-top: 5px;
    color: #323639
}

.imageArea{
    display:flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
}

.examStaticsArea {
    width: calc(100% - 10px);
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
}

.passItemSuccess, .passItemDefault {
    height: 50px;
    background: green;
}

.passItemDefault {
    background: #c0bebe;
}

.safeRecordItem{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 40px;
    align-items: center;
    padding: 0 10px;
}

.safeRecordItem:hover{
    cursor: pointer;
    color: #2e7ed8;
}
