.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    overflow-y: scroll;
}

.leftArea, .rightArea {
    width: 10vw;
    height: 100%;
    background: #fff;
}


.mainArea {
    width: 83vw;
    height: 100vh;
    /*overflow-y: scroll;*/
    overflow: visible; /* 避免裁剪 */
}

.title {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    padding-top: 30px;
}


.table {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(39, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    font-size: 20px;

}

.div1 {
    grid-area: 1 / 1 / 2 / 4;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: grid;
    place-items: center;
    font-weight: bold;
}

.div2 {
    grid-area: 1 / 4 / 2 / 7;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: grid;
    place-items: center;
    border-left: none;
}

.div3 {
    grid-area: 1 / 7 / 2 / 10;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: grid;
    place-items: center;
    border-left: none;
    font-weight: bold;
}

.div4 {
    grid-area: 1 / 10 / 2 / 13;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: grid;
    place-items: center;
    border-left: none;
}


.div5 {
    grid-area: 2 / 1 / 3 / 4;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: grid;
    place-items: center;
    border-top: none;
    font-weight: bold;
}

.div6 {
    grid-area: 2 / 4 / 3 / 7;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;
}

.div7 {
    grid-area: 2 / 7 / 3 / 10;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;
    font-weight: bold;
}

.div8 {
    grid-area: 2 / 10 / 3 / 13;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;
}

.div9 {
    grid-area: 3 / 1 / 4 / 4;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: grid;
    place-items: center;
    border-top: none;
    font-weight: bold;
}

.div10 {
    grid-area: 3 / 4 / 4 / 7;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;
}

.div11 {
    grid-area: 3 / 7 / 4 / 10;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;
    font-weight: bold;
}

.div12 {
    grid-area: 3 / 10 / 4 / 13;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;
}

.div13 {
    grid-area: 4 / 1 / 26 / 2;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.div14 {
    grid-area: 4 / 2 / 11 / 3;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;
    font-weight: bold;
}

.div15 {
    grid-area: 4 / 3 / 11 / 13;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    border-left: none;
}


.div15_item,.div17_item,.div19_item,.div21_item {
    padding:10px 0 0 15px;
    display: flex;
    flex-direction: row;
}


.div16 {
    grid-area: 11 / 2 / 16 / 3;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;
    font-weight: bold;
}

.div17 {
    grid-area: 11 / 3 / 16 / 13;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    border-left: none;
}

.div18 {
    grid-area: 16 / 2 / 22 / 3;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;
    font-weight: bold;
}

.div19 {
    grid-area: 16 / 3 / 22 / 13;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    /*display: grid;*/
    /*place-items: center;*/
    border-top: none;
    border-left: none;
}

.div20 {
    grid-area: 22 / 2 / 26 / 3;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;
    font-weight: bold;
}

.div21 {
    grid-area: 22 / 3 / 26 / 13;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    border-left: none;
}

.div22{
    grid-area: 26 / 1 / 30 / 2;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    text-align: center;
}

.div23{
    grid-area: 26 / 2 / 30 / 13;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;
}

.div24{
    grid-area: 30 / 1 / 32 / 2;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    text-align: center;
}

.div25{
    grid-area: 30 / 2 / 32 / 13;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;
}

.pic_title{
    grid-area: 32 / 1 / 36 / 2;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    text-align: center;
}

.pic_pic{
    grid-area: 32 / 2 / 36/ 13;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: flex;
    border-top: none;
    border-left: none;
    flex-direction: row;
    justify-content: center;
}

.div26{
    grid-area: 36 / 1 / 40 / 7;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
}

.signatureItem{
    margin-top:2vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.signatureDate{
    position: absolute;
    bottom:10px;
    right:30px;
}

.div27{
    grid-area: 36 / 7 / 40 / 13;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    border-left: none;
}

.div28{
    grid-area: 40 / 1 / 43 / 7;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    display:grid;
    place-items: center;
}

.div29{
    grid-area: 40 / 7 / 43 / 13;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    border-left: none;
}
.div30{
    grid-area: 36 / 1 / 37 / 13;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    /* 添加以下Flexbox属性以实现文本的垂直和水平居中 */
    display: flex; /* 启用Flexbox布局 */
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
}