.infoItem {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    margin-bottom: 5px;
    width: 100%;
}

.infoTitle {
    font-weight: bold;
    color: #0052D9;
    width: 120px;
}

.infoContent {
    width: calc(100% - 120px);
}