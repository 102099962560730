#components-layout-demo-side .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
    background: #fff;
}

.site-layout .site-layout-header{
    background: #fff;
    display: flex;
    justify-content: flex-end;
}


.site-layout-header .notification, .Avatar{
    margin-right: 2vw;
    cursor: pointer;
    display: flex;
    align-items: center;
}


.logo{
    height: 3vh;
}