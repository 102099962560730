.row {
    flex-direction: row;
    display: flex;
    font-size: 15px;
    margin:5px 0;
}

.title {
    font-weight: bold;
    color: #2485cb;
    margin-right: 10px;
    word-break: keep-all;
}

.content {
    word-break: break-word;
}