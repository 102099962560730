.Personal {
    height: 100vh;
    width: 100vw;
}

.Personal .topArea {
    height: 60vh;
    background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
    background-size: 100% 100%;
}

.topArea .avatarBlock {
    height: 40%;
    display: grid;
    place-items: center;
}

.avatarBlock .avatar {
    background-color: #a3d5ea;
}

.topArea .infoBlock {
    display: grid;
    place-items: center;
    gap: 0.5rem;

}

.infoBlock .name {
    font-size: 1.8vmax;
    font-weight: bolder;
    color: #ffffff;
    font-family: 方正舒体, serif;
}

.infoBlock .phone {
    font-size: 1.3vmax;
    font-weight: bold;
    color: #ffffff;
    font-family: "Lucida Calligraphy", serif;
}

.infoBlock .department{
    font-size: 1.3vmax;
    font-weight: bold;
    color: #ffffff;
    font-family: 方正舒体, serif;
}


.Personal .card {
    position: absolute;
    height: 10vh;
    width: 16vw;
    right: 42%;
    top: 55%;
    background: #ffffff;
    display: grid;
    place-items: center;
    font-size: 1.8vmax;
    font-family: 方正舒体, serif;
    color: #4194b6;
    box-shadow: 6px 6px 12px #bbb7b7,
    -6px -6px 12px #fccccc;

}

.Personal .bottomArea {
    height: 40vh;
    background: #dfe9ef;
    display: grid;
    place-items: center;
    font-size: 30px;
    color: #4a288a;
}