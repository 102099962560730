/*手机端*/
@media screen and (max-width: 768px) {
    .notice .title {
        height: 80px
    }

    .notice .title-1 {
        height: 36px;
        font-size: 25px;
    }

    .logo {
        width: 30px;
        height: 25px
    }

    .notice .title-2 {
        font-size: 18px;
        width: 175px;
        letter-spacing: 1px;
        height: 24px;
        padding-bottom: 2px;
    }

    .notice .title-2 .title-2-sec {
        font-size: 12px;
    }

    .text .text-title {
        width: 90vw;
        margin: 50px 5vw 0 5vw;
        font-size: 16px;
    }

    .text-paragraph {
        margin: 40px 10vw 0 10vw;
    }


    .text-paragraph-title {
        font-size: 14px;
        height: 25px;
        line-height: 25px;
    }

    .text-paragraph-title::before {
        width: 10px;
        height: 10px;
        margin-right: 2px;
    }

    .text-paragraph-content {
        font-size: 12px;
    }

    .text-paragraph-content::before {
        margin-right: 12px;
    }

    .text-paragraph-content-triangle {
        font-size: 12px;
    }

    .text-paragraph-content-triangle::before {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 9px solid #000;
        margin-left: 12px;
        margin-right: 2px;
    }

    .rightBottom {
        width: 100px;
        margin: 80px 10vw 0 calc(90vw - 100px);
        height: 100px;
    }

    .rightBottom-paragraph {
        font-size: 12px;
        height: 16px;
    }

    .recordTitleArea {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .recordTitle {
        line-height: 35px;
        height: 35px;
        font-size: 16px;
        letter-spacing: 1px;
    }

    .recordImageArea {
        padding-top: 5px;
        margin-left: 150px;
        text-align: center;
        font-size: 8px;
    }

    .recordImage {
        width: 60px;
        height: 60px;
    }

    .tableTitle {
        margin-top: 3vh;
        font-size: 14px;
        text-align: center;
    }

    .secTableTitle {
        margin-top: 5px;
        font-size: 12px;
        text-align: center;
    }

    .table {
        margin-top: 10px;
        font-size: 10px;
    }

    .rowOneParent, .rowTwoParent, .rowFourParent, .rowEachMonth {
        line-height: 1.5;
    }

    .rowThreeParent {
        line-height: 2;
        font-size: 14px;
    }
}

/*电脑端*/
@media screen and (min-width: 769px) {
    .notice .title {
        height: 120px;
    }

    .notice .title-1 {
        height: 56px;
        font-size: 40px;
    }

    .logo {
        width: 50px;
        height: 40px
    }

    .notice .title-2 {
        font-size: 30px;
        width: 310px;
        letter-spacing: 5px;
        height: 36px;
        padding-bottom: 5px;
    }

    .notice .title-2 .title-2-sec {
        font-size: 15px;
    }

    .text .text-title {
        font-size: 30px;
        margin-top: 100px;
    }

    .text-paragraph {
        margin: 60px 10vw 0 10vw;
    }

    .text-paragraph-title {
        font-size: 22px;
        height: 50px;
        line-height: 50px;
    }

    .text-paragraph-title::before {
        width: 18px;
        height: 18px;
        margin-right: 5px;
    }

    .text-paragraph-content {
        font-size: 16px;
    }

    .text-paragraph-content::before {
        margin-right: 23px;
    }

    .text-paragraph-content-triangle {
        font-size: 16px;
    }

    .text-paragraph-content-triangle::before {
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 13px solid #000;
        margin-left: 23px;
        margin-right: 5px;
    }

    .rightBottom {
        width: 160px;
        margin: 120px 10vw 0 calc(90vw - 160px);
        height: 150px;
    }

    .rightBottom-paragraph {
        font-size: 18px;
        height: 23px;
    }

    .recordTitleArea {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .recordTitle {
        line-height: 110px;
        height: 110px;
        font-size: 30px;
        letter-spacing: 5px;
    }

    .recordImageArea {
        padding-top: 10px;
        margin-left: 30px;
        text-align: center;
        font-size: 14px;
    }

    .recordImage {
        width: 100px;
        height: 100px;
    }

    .tableTitle {
        margin-top: 5vh;
        font-size: 24px;
        text-align: center;
        letter-spacing: 2px;
    }

    .secTableTitle {
        margin-top: 10px;
        font-size: 18px;
        text-align: center;
    }

    .table {
        margin-top: 30px;
        font-size: 15px;
    }

    .rowOneParent, .rowTwoParent, .rowFourParent, .rowEachMonth {
        line-height: 2;
    }

    .rowThreeParent {
        line-height: 3;
        font-size: 18px;
    }
}


.notice {
    margin-top: 15px;
}

.notice .title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 3px solid #000;
    width: 80vw;
    margin: 0 10vw;
}

.notice .title-1 {
    font-weight: bold;
}

.notice .title-2 {
    border-bottom: 1px solid #000;
}

.secLine {
    width: 80vw;
    margin: 10px 10vw;
    border-bottom: 1px solid #000;
}

.text .text-title {
    align-items: center;
    text-align: center;
    font-weight: bold;
}

.text-paragraph {
    width: 80vw;
}

.text-paragraph-title {
    font-weight: bold;
}

.text-paragraph-title::before {
    content: "";
    display: inline-block;
    background-color: #000;
    border-radius: 50%;
}

.text-paragraph-content {
    letter-spacing: 1px;
}

.text-paragraph-content::before {
    content: "";
    display: inline-block;
}

.text-paragraph-content-triangle {
    letter-spacing: 1px;
}

.text-paragraph-content-triangle::before {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
}


.rightBottom-paragraph {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

.record {
    width: 90vw;
    margin: 10vh 5vw;
}

.recordTitle {
    display: flex;
    font-weight: bold;
    white-space: break-spaces;
}

.rowOneParent {
    display: grid;
    grid-template-columns: 1fr 2fr 4fr 2fr repeat(2, 2fr) repeat(2, 3fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.row1-div1 {
    grid-area: 1 / 1 / 2 / 2;
    border: 1px solid #000000;
    display: grid;
    place-items: center;    padding:0 3px;
}

.row1-div2 {
    grid-area: 1 / 2 / 2 / 3;
    border: 1px solid #000000;
    border-left: none;
    display: grid;
    place-items: center;    padding:0 3px;
}

.row1-div3 {
    grid-area: 1 / 3 / 2 / 4;
    border: 1px solid #000000;
    border-left: none;
    display: grid;
    place-items: center;    padding:0 3px;
}

.row1-div4 {
    grid-area: 1 / 4 / 2 / 5;
    border: 1px solid #000000;
    border-left: none;
    display: grid;
    place-items: center;    padding:0 3px;
}

.row1-div5 {
    grid-area: 1 / 5 / 2 / 6;
    border: 1px solid #000000;
    border-left: none;
    display: grid;
    place-items: center;    padding:0 3px;
}

.row1-div6 {
    grid-area: 1 / 6 / 2 / 7;
    border: 1px solid #000000;
    border-left: none;
    display: grid;
    place-items: center;    padding:0 3px;
}

.row1-div7 {
    grid-area: 1 / 7 / 2 / 8;
    border: 1px solid #000000;
    border-left: none;
    display: grid;
    place-items: center;    padding:0 3px;
}

.row1-div8 {
    grid-area: 1 / 8 / 2 / 9;
    border: 1px solid #000000;
    border-left: none;
    display: grid;
    place-items: center;    padding:0 3px;
}

.rowTwoParent {
    display: grid;
    grid-template-columns: 2fr 4fr 1fr 6fr 6fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.row2-div1 {
    grid-area: 1 / 1 / 2 / 2;
    border: 1px solid #000000;
    display: grid;
    place-items: center;
    border-top: none;    padding:0 3px;
}

.row2-div2 {
    grid-area: 1 / 2 / 2 / 3;
    border: 1px solid #000000;
    border-left: none;
    display: grid;
    place-items: center;
    border-top: none;    padding:0 3px;
}

.row2-div3 {
    grid-area: 1 / 3 / 2 / 4;
    border: 1px solid #000000;
    border-left: none;
    display: grid;
    place-items: center;
    border-top: none;    padding:0 3px;
}

.row2-div4 {
    grid-area: 1 / 4 / 2 / 5;
    border: 1px solid #000000;
    border-left: none;
    display: grid;
    place-items: center;
    border-top: none;    padding:0 3px;
}

.row2-div5 {
    grid-area: 1 / 5 / 2 / 6;
    border: 1px solid #000000;
    border-left: none;
    display: grid;
    place-items: center;
    border-top: none;    padding:0 3px;
}

.rowThreeParent {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr;
}

.row3-div1 {
    grid-area: 1 / 1 / 2 / 2;
    border: 1px solid #000000;
    display: grid;
    place-items: center;
    border-top: none;
    font-weight: bold;
}

.rowFourParent {
    display: grid;
    /*grid-template-columns: 1fr 3fr repeat(3, 2fr) repeat(2, 3fr);*/
    grid-template-columns: 1fr repeat(4, 2fr);
    grid-template-rows: repeat(2, 2fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.row4-div1 {
    grid-area: 1 / 1 / 3 / 2;
    border: 1px solid #000000;
    display: grid;
    place-items: center;
    border-top: none;    padding:0 3px;
}

.row4-div2 {
    grid-area: 1 / 2 / 3 / 3;
    border: 1px solid #000000;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;    padding:0 3px;
}

.row4-div3 {
    grid-area: 1 / 3 / 2 / 6;
    border: 1px solid #000000;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;    padding:0 3px;
}

/*.row4-div4 {*/
/*    grid-area: 1 / 6 / 2 / 8;*/
/*    border: 1px solid #000000;*/
/*    display: grid;*/
/*    place-items: center;*/
/*    border-top: none;*/
/*    border-left: none;    padding:0 3px;*/

/*}*/

.row4-div5 {
    grid-area: 2 / 3 / 3 / 4;
    border: 1px solid #000000;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;    padding:0 3px;

}

.row4-div6 {
    grid-area: 2 / 4 / 3 / 5;
    border: 1px solid #000000;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;    padding:0 3px;
}

.row4-div7 {
    grid-area: 2 / 5 / 3 / 6;
    border: 1px solid #000000;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;    padding:0 3px;
}

.row4-div8 {
    grid-area: 2 / 6 / 3 / 7;
    border: 1px solid #000000;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;
    padding:0 3px;
}

.row4-div9 {
    grid-area: 2 / 7 / 3 / 8;
    border: 1px solid #000000;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;
    padding:0 3px;
}

.rowEachMonth {
    display: grid;
    /*grid-template-columns: 1fr 3fr repeat(3, 2fr) repeat(2, 3fr);*/
    grid-template-columns: 1fr repeat(4, 2fr);
    grid-template-rows: 2fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.month-div1 {
    grid-area: 1 / 1 / 2 / 2;
    border: 1px solid #000000;
    display: grid;
    place-items: center;
    border-top: none;
    padding:0 3px
}

.month-div2 {
    grid-area: 1 / 2 / 2 / 3;
    border: 1px solid #000000;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;
    padding:0 3px
}

.month-div3 {
    grid-area: 1 / 3 / 2 / 4;
    border: 1px solid #000000;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;
    padding:0 3px
}

.month-div4 {
    grid-area: 1 / 4 / 2 / 5;
    border: 1px solid #000000;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;
    padding:0 3px
}

.month-div5 {
    grid-area: 1 / 5 / 2 / 6;
    border: 1px solid #000000;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;
    padding:0 3px
}

.month-div6 {
    grid-area: 1 / 6 / 2 / 7;
    border: 1px solid #000000;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;
    padding:0 3px
}

.month-div7 {
    grid-area: 1 / 7 / 2 / 8;
    border: 1px solid #000000;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;
    padding:0 3px
}

