.Login {
    display: grid;
    grid-template-columns: 0.6fr 0.8fr 0.6fr;
    grid-template-rows:0.2fr 1fr 0.2fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
    ". . ."
    ". LoginCard ."
    ". . .";
    height: 100vh;
    width: 100vw;
    background-image: url("/src/assets/images/back.png");
    background-size: 100%;
}


.LoginCard {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-area: LoginCard;
    justify-items: center;
    justify-content: center;
    align-items: center;
    align-content: center;
}


.LoginCard .loginCardMain {
    width: 25vw;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 5px 5px 5px #c5bbba,
    -5px -5px -5px #e3f7f8;
}

.loginCardMain .Title {
    height: 12vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.loginCardMain .Phone, .loginCardMain .PassWord {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
}

.Phone .phoneText {
    /*color:#ffffff;*/
    margin: auto 1vw auto 0;
    text-align: center;
    font-size: 1vmax;
    font-weight: 600;
}

.Phone .phoneItem {
    margin: auto 0;
}


.Phone .phoneInput {
    height: 5vh;
    width: 16vw;
}

.PassWord .PasswordText {
    margin: auto 1vw auto 0;
    text-align: center;
    font-size: 1vmax;
    font-weight: 600;
    /*color:#ffffff*/
}


.PassWord .PasswordMain {
    margin: auto 0;
    width: 16vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.PasswordMain .PasswordItem {
    margin: auto 1vw auto 0;
}


.loginButton {
    margin-top: 4vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Login .Footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.loginBottom {
    position: absolute;
    bottom: 10px;
    /*background: #ffffff;*/
    width: 100%;
    text-align:center;
    color: #055078
}