.mainHead {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.searchContent {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
}

.searchButton {
    width: 10%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.row {
    flex-direction: row;
    display: flex;
    font-size: 15px;
    margin:5px 0;
}

.title {
    font-weight: bold;
    color: #2485cb;
    margin-right: 10px;
}