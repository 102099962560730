.hydrantMap {
    overflow: hidden;
}

.header {
    height: 60px;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dadada;
    position: relative;
}

.return {
    padding-left: 1vw;
    width: 15vw;
    display: flex;
    align-items: center;
    height: 100%;
    color: #155f88;
    font-size: 1rem;
    cursor: pointer;
}

.nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 70vw;
    align-items: center;
}

.map {
    height: calc(100vh - 70px);
}

.switchBtn {
    width: 15vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.assetList {
    width: 380px;
    height: 85vh;
    position: absolute;
    right: 2vw;
    top: 70px;
    z-index: 1;
    background-color: #ffffff;
    box-shadow: 20px 20px 60px #bebebe;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.listHeader {
    text-align: center;
    font-size: 1.1rem;
    font-weight: bold;
    color: #107262;
    margin-top: 2vh;
}

.list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.listItem {
    width: 250px;
    height: 10vh;
    margin: 0vh 30px;
    background: #f9fffb;
    box-shadow: inset 20px 20px 60px #b4e5b4,
    inset -20px -20px 60px #ffffff;
    border-radius: 6px;
    display: grid;
    place-items: center;
    font-weight: bold;
    padding: 0 20px;
}

.listItem:hover {
    cursor: pointer;
    background-color: #107262;
    color: #ffffff;
}

.listItemChecked {
    width: 250px;
    height: 10vh;
    margin: 0vh 30px;
    background: #14602e;
    box-shadow: inset 10px 10px 30px #61b97f,
    inset -10px -10px 30px #5ba675;
    border-radius: 6px;
    display: grid;
    place-items: center;
    font-weight: bold;
    color: #ffffff;
    padding: 0 20px;
}

.listItemNoData {
    font-size: 20px;
    display: grid;
    place-items: center;
    font-weight: bold;
}

.listFooter {
    /*position: absolute;*/
    /*bottom: 10px;*/
    /*left: 40%;*/
    margin: 2vh 15px;
    width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.listRight {
    position: absolute;
    right: 25px;
}

.infoWindow {
    width: 40px;
    height: 40px;
    background: #ffffff;
}

.row {
    flex-direction: row;
    display: flex;
    font-size: 15px;
    margin: 5px 0;
}

.title {
    font-weight: bold;
    color: #2485cb;
    margin-right: 10px;
}

.imageArea {
    /*width: 500px;*/
    height: 150px;
    position: absolute;
    /*right: calc((100vw - 500px) / 2);*/
    bottom: 20px;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 20px 20px 60px #bebebe;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.imageAreaHeader {
    text-align: center;
    font-size: 1.1rem;
    font-weight: bold;
    margin-top: 1vh;
}

.imageList{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px ;
}