.main {
    width: 100%;
    height: 100%;
}

.rectifyButton{
    position: fixed ;
    top:20px;
    margin:0 30vw;
    width: 20vw;
    height: 50px;
    background: #154e7f;
    border-radius: 10px;
    z-index:1000;
    box-shadow:  6px 6px 12px #bebebe,
    -6px -6px 12px #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
    color: #ffffff;
    cursor: pointer;
}

.mainHead {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.searchContent {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
}

.searchButton {
    width: 15%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

@keyframes shrink {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}