.container {
    width: 100vw;
    /*height: 100vh;*/
    display: flex;
    flex-direction: row;
    /*overflow-y: scroll;*/
}

.leftArea, .rightArea {
    width: 10vw;
    height: auto;
    background: #fff;
}


.mainArea {
    min-height: 100vh;  /* 最小高度为视窗高度 */
    padding-bottom: 0;
    margin-bottom: 0;
    width: 100%;
    /*overflow: visible;*/
}

.title {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    padding-top: 30px;
}


.table {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(52, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    font-size: 25px;
}

.div1 {
    grid-area: 1 / 1 / 5 / 4;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: grid;
    place-items: center;
    font-weight: bold;
}

.div2 {
    grid-area: 1 / 4 / 5 / 7;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: grid;
    place-items: center;
    border-left: none;
}

.div3 {
    grid-area: 1 / 7 / 5 / 10;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: grid;
    place-items: center;
    border-left: none;
    font-weight: bold;
}

.div4 {
    grid-area: 1 / 10 / 5 / 13;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: grid;
    place-items: center;
    border-left: none;
}


.div5 {
    grid-area: 5 / 1 / 9 / 2;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: grid;
    place-items: center;
    border-top: none;
    font-weight: bold;
}

.div6 {
    grid-area: 5 / 2 / 9 / 4;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;
}

.div7 {
    grid-area: 5 / 4 / 9 / 5;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;
    font-weight: bold;
}

.div8 {
    grid-area: 5 / 5 / 9 / 7;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;
}

.div9 {
    grid-area: 5 / 7 / 9 / 8;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;
    font-weight: bold;
}

.div10 {
    grid-area: 5 / 8 / 9 / 10;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;
}

.div11 {
    grid-area: 5 / 10 / 9 / 11;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;
    font-weight: bold;
}

.div12 {
    grid-area: 5 / 11 / 9 / 13;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;
}

.div13 {
    grid-area: 9 / 1 / 13 / 2;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.div14 {
    grid-area: 9 / 2 / 13 / 4;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;
}

.div15 {
    grid-area: 9 / 4 / 13 / 5;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-left: none;
    border-top: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}


.div15_item,.div17_item,.div19_item,.div21_item {
    padding:10px 0 0 15px;
    display: flex;
    flex-direction: row;
}


.div16 {
    grid-area: 9 / 5 / 13 / 7;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;
}

.div17 {
    grid-area: 9 / 7 / 13 / 10;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-left: none;
    border-top: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.div18 {
    grid-area: 9 / 10 / 13 / 14;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    display: grid;
    place-items: center;
    border-top: none;
    border-left: none;
}

.div19 {
    grid-area: 13 / 1 / 33 / 2;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.div20 {
    grid-area: 13 / 2 / 17 / 3;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    border-left: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.div21 {
    grid-area: 13 / 3 / 17 / 5;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    border-left: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.div22{
    grid-area: 13 / 5 / 17 / 6;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    border-left: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.div23{
    grid-area: 13 / 6 / 17 / 7;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    border-left: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.div24{
    grid-area: 13 / 7 / 17 / 8;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    border-left: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.div25{
    grid-area: 13 / 8 / 17 / 10;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    border-left: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.div26{
    grid-area: 13 / 10 / 17 / 11;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    border-left: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.signatureItem{
    margin-top:2vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.signatureDate{
    position: absolute;
    bottom:10px;
    right:30px;
}

.div27{
    grid-area: 13 / 11 / 17 / 13;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    border-left: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.div28{
    grid-area: 17 / 2 / 25 / 3;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    border-left: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.div29{
    grid-area: 17 / 2 / 25 / 13;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    border-left: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.div30{
    grid-area: 25 / 2 / 29 / 4;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    border-left: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}
.div31{
    grid-area: 25 / 4 / 29 / 7;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    border-left: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.div32{
    grid-area: 25 / 7 / 29 / 9;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    border-left: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}
.div33{
    grid-area: 25 / 9 / 29 / 13;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    border-left: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.div34{
    grid-area: 29 / 2 / 33 / 4;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    border-left: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}
.div35{
    grid-area: 29 / 4 / 33 / 7;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    border-left: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.div36{
    grid-area: 29 / 7 / 33 / 9;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    border-left: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}
.div37{
    grid-area: 29 / 9 / 33 / 13;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    border-left: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.div38{
    grid-area: 33 / 1 / 42 / 2;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
 }
.div39{
    grid-area: 33 / 2 / 42 / 13;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    border-left: none;
}
.div40{
    grid-area: 42 / 1 / 47 / 2;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}
.div41{
    grid-area: 42 / 2 / 47 / 13;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    border-left: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.div42{
    grid-area: 47 / 1 / 52 / 2;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}
.div43{
    grid-area: 47 / 2 / 52 / 13;
    border: 1px solid #000;
    padding: 0 3px;
    position: relative;
    border-top: none;
    border-left: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
