.user_management_layout{
    margin: 12px;
}
.user_management_header{
    font-size: 24px;
    height: 32px;
}

.user_management_search{
    height: 32px;
    display: flex;
    justify-content: space-between;
    margin: 12px 0;
}

.user_management_add{
    margin: 10px;
}